<template>
  <section class="gallery">
    <div class="gallery__container section-main grid">
      <div class="gallery__image-1">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                    ../assets/images/rebrandable/gallery-1.webp?width=200 200w,
                    ../assets/images/rebrandable/gallery-1.webp?width=400 400w,
                    ../assets/images/rebrandable/gallery-1.webp?width=600 600w,
                    ../assets/images/rebrandable/gallery-1.webp?width=800 800w,
                "
            src="../assets/images/rebrandable/gallery-1.webp"
            sizes="(max-width: 400px) 100vw, 400px"
        />
      </div>
      <div class="gallery__image-2">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                    ../assets/images/rebrandable/gallery-2.webp?width=200 200w,
                    ../assets/images/rebrandable/gallery-2.webp?width=400 400w,
                    ../assets/images/rebrandable/gallery-2.webp?width=600 600w,
                    ../assets/images/rebrandable/gallery-2.webp?width=800 800w,
                "
            src="../assets/images/rebrandable/gallery-2.webp"
            sizes="(max-width: 400px) 100vw, 400px"
        />
      </div>
      <div class="gallery__image-3">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                    ../assets/images/rebrandable/gallery-3.webp?width=200 200w,
                    ../assets/images/rebrandable/gallery-3.webp?width=400 400w,
                    ../assets/images/rebrandable/gallery-3.webp?width=600 600w,
                    ../assets/images/rebrandable/gallery-3.webp?width=800 800w,
                "
            src="../assets/images/rebrandable/gallery-3.webp"
            sizes="(max-width: 400px) 100vw, 400px"
        />
      </div>
      <div class="gallery__image-4">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                    ../assets/images/rebrandable/gallery-4.webp?width=200 200w,
                    ../assets/images/rebrandable/gallery-4.webp?width=400 400w,
                    ../assets/images/rebrandable/gallery-4.webp?width=600 600w,
                    ../assets/images/rebrandable/gallery-4.webp?width=800 800w,
                "
            src="../assets/images/rebrandable/gallery-4.webp"
            sizes="(max-width: 400px) 100vw, 400px"
        />
      </div>
      <div class="gallery__image-5">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                    ../assets/images/rebrandable/gallery-5.webp?width=200 200w,
                    ../assets/images/rebrandable/gallery-5.webp?width=400 400w,
                    ../assets/images/rebrandable/gallery-5.webp?width=600 600w,
                    ../assets/images/rebrandable/gallery-5.webp?width=800 800w,
                "
            src="../assets/images/rebrandable/gallery-5.webp"
            sizes="(max-width: 400px) 100vw, 400px"
        />
      </div>
      <div class="gallery__image-6">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                    ../assets/images/rebrandable/gallery-6.webp?width=200 200w,
                    ../assets/images/rebrandable/gallery-6.webp?width=400 400w,
                    ../assets/images/rebrandable/gallery-6.webp?width=600 600w,
                    ../assets/images/rebrandable/gallery-6.webp?width=800 800w,
                "
            src="../assets/images/rebrandable/gallery-6.webp"
            sizes="(max-width: 400px) 100vw, 400px"
        />
      </div>
      <div class="gallery__image-7">
        <img
            loading="lazy"
            role="presentation"
            alt=""
            srcset="
                    ../assets/images/rebrandable/gallery-7.webp?width=200 200w,
                    ../assets/images/rebrandable/gallery-7.webp?width=400 400w,
                    ../assets/images/rebrandable/gallery-7.webp?width=600 600w,
                    ../assets/images/rebrandable/gallery-7.webp?width=800 800w,
                "
            src="../assets/images/rebrandable/gallery-7.webp"
            sizes="(max-width: 400px) 100vw, 400px"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
</script>

<style scoped>
.gallery{
  position: relative;
  overflow: hidden;
  background-color: var(--bg-home);
}

.gallery__container{
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 75vw 50vw 50vw;
  gap: 10px;
  padding: 50px 16px 25px;
  background-color: var(--secundary);
  border-radius: 0 0 60px 60px;
}

[class^="gallery__image"]{
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

[class^="gallery__image"] > img{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.gallery__image-1{
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.gallery__image-2{
  grid-column: 1 / -1;
  grid-row: 2 / 3;
}

.gallery__image-2 > img{
  transform: translate(-50%, -55%);
  width: initial;
  height: initial;
}

.gallery__image-4{
  grid-column: 2 / -1;
  grid-row: 1 / 2;
}

.gallery__image-3,
.gallery__image-5{
  display: none;
}

.gallery__image-6{
  grid-column: 1 / 2;
  grid-row: 3 / -1;
  border-radius: 8px 8px 8px 50px;
}

.gallery__image-7{
  grid-column: 2 / 3;
  grid-row: 3 / -1;
  border-radius: 8px 8px 50px 8px;
}

@media screen and (min-width: 600px){
  .gallery__container{
    grid-template-rows: 55vw 50vw 50vw;
    gap: 24px;
  }
}

@media screen and (min-width: 905px){
  .gallery__container{
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 500px 250px 250px 450px;
    gap: 40px;
    padding: 50px 32px;
    border-radius: 0 0 150px 150px;
  }

  .gallery__image-3,
  .gallery__image-5{
    display: block;
  }

  .gallery__image-1{
    grid-column: 1 / 3;
  }

  .gallery__image-2{
    grid-column: 3 / -1;
    grid-row: 1 / 2;
  }

  .gallery__image-3{
    grid-column: 1 / 3;
    grid-row: 2 / 4;
  }

  .gallery__image-4{
    display: block;
    grid-column: 3 / 5;
    grid-row: 2 / 4;
  }

  .gallery__image-5{
    grid-column: 5 / -1;
    grid-row: 2 / 4;
  }

  .gallery__image-6{
    grid-column: 1 / 4;
    grid-row: 4 / -1;
    border-radius: 8px 8px 8px 120px;
  }

  .gallery__image-7{
    grid-column: 4 / -1;
    grid-row: 4 / -1;
    border-radius: 8px 8px 120px 8px;
  }
}

@media screen and (min-width: 1240px){
  .gallery__image-7 > img{
    transform: translate(-50%, -60%);
    height: initial;
  }
}
</style>