<template>
  <div class="input-container">
    <div>
      <label class="input-label tp-label-responsive"
            :style="{background : `linear-gradient(180deg, ${bg_color} 0%, ${bg_color} 45%, white 45.1%, white 100%)`}" :class="{ 'activated': modelValue.length > 0 }">
        {{ placeholder }}
      </label>
      <div @click="toggleDisplayList"
           class="input__class tp-body-responsive"
           tabindex="0"
      >
        {{txt_in_filter_btn()}}
        <svg
            :class="display_list? 'dropdown__svg__active': ''"
            xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 50 50">
          <defs>
            <clipPath id="clip-drop_icon">
              <rect width="50" height="50"/>
            </clipPath>
          </defs>
          <g id="drop_icon" clip-path="url(#clip-drop_icon)">
            <g id="_1" data-name="1" transform="translate(0 38.876) rotate(-90)">
              <path id="Path_21057" data-name="Path 21057" d="M25,50a1.876,1.876,0,0,1-1.327-.549L.549,26.327a1.876,1.876,0,0,1,0-2.653L23.672.55A1.876,1.876,0,1,1,26.326,3.2L4.529,25l21.8,21.8A1.876,1.876,0,0,1,25,50Z" transform="translate(0 0)" fill="currentColor"/>
            </g>
          </g>
        </svg>
      </div>
      <div
          :class="display_list? 'select__component__dropdown__content__active': ''"
          class="select__component__dropdown__content"
      >
        <div
            class="item_lista"
            v-for="(item, index) in optionsSelect"
            :key="`multipleSelectComponent__${name}__${index}`"
        >
          <input
              type="checkbox"
              style="display: none"
              :name="name"
              :id="`multiple_select__${name}__${index}`"
              :value="item"
              :checked="modelValue.includes(item)"
              @change="onChanged($event, item)"
          >

          <label class="label_local_item tp-body-responsive" :for="`multiple_select__${name}__${index}`" v-if="valueObj != null">
            {{ item[valueObj] }}
          </label>
          <label class="label_local_item tp-body-responsive" :for="`multiple_select__${name}__${index}`" v-if="valueObj == null">
            {{ item }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref} from "vue";

export default defineComponent({
  name: "MultipleSelectComponent",

  emits: ['update:modelValue', 'filterChanged', ],

  props: ['optionsSelect', 'modelValue', 'name', 'valueObj', 'placeholder', 'bg_color', 'isActive'],

      watch: {
        isActive: function () {
          this.display_list = this.isActive;
        }
      },

  setup(props, { emit }) {
    const display_list = ref(false);

    const onChanged = (event, item) => {
      const value = item;
      const isChecked = event.target.checked;
      const updatedModelValue = [...props.modelValue];

      if (isChecked) {
        updatedModelValue.push(value);
      } else {
        const index = updatedModelValue.indexOf(value);
        if (index !== -1) {
          updatedModelValue.splice(index, 1);
        }
      }
      emit("update:modelValue", updatedModelValue);
      emit('filterChanged')
    };

    function toggleDisplayList() {
      display_list.value = !display_list.value; // Actualiza display_list usando el ref
    }

    function txt_in_filter_btn() {
      if (props.modelValue.length === 0) {
        return props.placeholder
      }

      if (props.modelValue.length < props.optionsSelect.length) {
        return `${props.modelValue.length} seleccionados`
      }
      return 'Todos'

    }

    return { onChanged, txt_in_filter_btn, toggleDisplayList, display_list };
  },
})
</script>

<style scoped>
.input__class{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>