<template>
  <section class="plato">
    <div class="plato__image">
      <img
          loading="lazy"
          :alt=product.name
          :srcset="`${backgroundPlaceholderItem(product)}?width=200 200w`"
          :src=backgroundPlaceholderItem(product)
          sizes="(max-width: 200px) 100vw, 200px"
      />
    </div>
    <div class="plato__information">
      <div class="plato__text">
        <h3 class="tp-title-responsive">{{product.name}}</h3>
        <p class="tp-body-responsive">{{product.description}}</p>
      </div>
      <div class="plato__prices-btn">
        <div class="plato__prices">
          <p class="discount tp-title-responsive" v-if="product.has_discount">S/.{{product.promo_price}}</p>
          <p class="anterior tp-body-responsive" v-if="product.has_discount">S/.{{product.price.toFixed(2)}}</p>
          <p class="tp-title-responsive" v-else>S/.{{product.price.toFixed(2)}}</p>
        </div>
      </div>
    </div>
    <div class="plato__discount" v-if="product.has_discount">
      <p class="tp-body-responsive">{{parseFloat((parseFloat(product.promo_price) - parseFloat(product.price))/(parseFloat(product.price))*100).toFixed(0)}}%</p>
    </div>
  </section>
</template>

<script>

export default {
  name: 'ProductoCarta',
  components: {},
  props: ['product'],
  methods: {

    backgroundPlaceholderItem() {
      let url;
      try {
        // eslint-disable-next-line no-unused-vars
        url = new URL(this.product.img)
        if (!this.product.is_available) {
          return this.product.img;
        }
        return this.product.img;
      } catch (error) {
        if (!this.product.is_available) {
          return process.env.VUE_APP_IMG_PLACEHOLDER;
        }
        return process.env.VUE_APP_IMG_PLACEHOLDER;
      }
    },
  }
}
</script>

<style scoped>
  .plato{
    position: relative;
    display: flex;
    border-radius: 8px;
    box-shadow: 0 0 4px rgba(41, 41, 41, .2);
    overflow: hidden;
    background: var(--text-white);
    cursor: pointer;
    height: 115px;
    padding: 8px;
    width: 100%;
    scale: 1;
    transition: scale .3s linear;
  }

  .plato__image{
    position: relative;
    width: 100px;
    min-width: 100px;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
  }

  .plato___image img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .plato__information {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: -moz-max-content;
    padding: 0 0 0 10px;
    z-index: 1;
  }

  .plato__information::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all .3s linear;
    z-index: 0;
  }


  .plato__information::after {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 40%, rgba(255, 255, 255, 0) 70%);
    opacity: 1;
  }

  .plato__information p{
    margin-top: 4px;
  }

  .plato__text{
    position: relative;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    height: 100%;
  }

  .plato__prices-btn{
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: auto;
  }

  .plato__prices-btn button{ display: none; }

  .plato__prices{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: auto;
  }

  .plato__prices p{
    text-decoration: none
  }

  .plato__prices .anterior{
    text-decoration: line-through
  }

  .plato__prices .discount{
    margin-top: 0;
  }

  .plato__discount{
    position: absolute;
    top: 5px;
    left: 0;
    padding: 5px 10px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: var(--primary);
  }

  .plato__discount p{
    color: var(--text-white);
    font-weight: var(--semibold);
  }

  @media screen and (min-width: 600px){
    .plato{
      height: 105px;
    }

    .plato__image{
      width: 78px;
      min-width: 78px;
    }

    .plato__information::after {
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0) 70%);
    }
  }

  @media screen and (min-width: 905px){
    .plato{
      height: 140px;
      padding: 0;
    }

    .plato:hover{
      scale: 1.02;
    }

    .plato__image{
      width: 140px;
      min-width: 140px;
      border-radius: 8px 0 0 8px;
    }

    .plato__information{
      padding: 8px 16px;
    }

    .plato__information::after {
      background: linear-gradient(0deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 70%);
    }

    .plato__text p{
      margin-top: 6px;
    }
  }

  @media screen and (min-width: 1240px){
    .plato{
      height: 200px;
      padding: 0;
    }

    .plato__image{
      width: 200px;
      min-width: 200px;
    }

    .plato__text {
      -webkit-line-clamp: 5;
    }

    .plato__information{
      padding: 12px 16px;
    }

    .plato__prices{
      margin-left: initial;
    }

    .plato__discount{
      top: 15px;
    }
  }

  @media screen and (min-width: 1440px) {
    .plato__text p{
      margin-top: 10px;
    }
  }
  </style>