<template>
  <section class="cartadigital">
    <!--    <WhatsappIconComponent-->
    <!--        :phone="whatsapp_phone"-->
    <!--    ></WhatsappIconComponent>-->
    <div class="cartadigital__hero" :style="backgroundPlaceholderLocal()">
      <div class="banner__container">
        <div class="box_sombra_sobre_foto"></div>
        <h2 class="carta__title tp-head-responsive" v-if="orderLocal !== null">{{orderLocal.name}}</h2>
      </div>
    </div>
    <section class="cartadigital__filters section-main">
      <h1 class="tp-head-responsive">{{ $t('carta.txt_explora_nuestra_carta') }}</h1>
      <div class="filters">
        <ChipMobileComponent
            :optionsSelect="rawListServCategories"
            :name="'select_categories_local'"
            :required=false
            :placeholder="''"
            :bg_color="'white'"
            :valueObj="'name'"
            v-model="auxCategSelected"
            @dataFiltered="selectedAuxCateg"
        >
        </ChipMobileComponent>
        <div class="filter__chips">
          <label
              class="chip-personalized"
              :class="{ 'chip-personalized-selected': categorySelected === null }"
              :for="`todas__categorias`">
            <input
                type="radio"
                :value=null
                :name="'dr_filter_chips_carta_local'"
                :id="`todas__categorias`"
                style="display: none"
                v-model="categorySelected"
                @change="selectedRadioBtn"
            >
            {{ $t('placeholder.txt_mostrar_todo') }}
          </label>
          <DarkRadioFilterChip
              :name="'dr_filter_chips_carta_local'"
              :valueObj="null"
              :options_filterChips="rawListServCategories"
              v-model="categorySelected"
              @filterChanged="selectedRadioBtn"
          >
          </DarkRadioFilterChip>
        </div>
        <SearchComponent
            :placeholder="$t('placeholder.txt_buscar_por_plato')"
            v-model="search_input"
            @searchChanged="changedSearchInfo"
        >
        </SearchComponent>
      </div>
    </section>
    <section
        class="cartadigital__dish section-main"
        v-for="(item, index) in listCategorias"
        :id="'c1'+ item.id"
        :key="index"
    >
      <div v-show="listFilteredProducts.filter(obj => {
      if (obj.category === item.id) {
        return item
      }
    }).length > 0">
        <h2 class="tp-title-responsive" :id="`subtitulo_carta__${item.id}`">{{ item.name }}</h2>
        <section class="dish__list">
          <ProductoCarta
              v-for="(product, index2) in listFilteredProducts.filter(obj => {
              if (obj.category === item.id) {
                return obj
              }
            })"
              :key="'pro' + index2"
              :id="`product${product.id}`"
              @click.stop="setNewProductSelected(product)"
              :product="product"
          >
          </ProductoCarta>
        </section>
      </div>
    </section>
    <MobileFooterComponent></MobileFooterComponent>
    <transition name="slide-fade">
      <ModalProductCustomization
          v-if="showModalCustomization"
          :from_payment="false"
          :list_additional_items="list_additional_items"
          :productId="modal_prod_id"
          :selectedItemCarrito=null
          @close="closeModalCustomization"
          @pay="openModalCarrito"
      >
      </ModalProductCustomization>
    </transition>
    <transition name="slide-fade">
      <LoadingBigComponent
          v-if="loading"></LoadingBigComponent>
    </transition>
  </section>
</template>

<script>
import LocalServices from "@/services/local-services"
import { mapState, mapMutations } from 'vuex'
// import WhatsappIconComponent from "@/components/WhatsappIconComponent";
import ModalProductCustomization from "@/components/MODALS/ModalProductCustomization";
import LoadingBigComponent from "@/components/Recursos/LoadingBigComponent";
import ProductoCarta from "@/components/ProductoCarta";
import DarkRadioFilterChip from "@/components/new/buttons/DarkRadioFilterChip.vue";
import SearchComponent from "@/components/new/inputs/SearchComponent.vue";
import MobileFooterComponent from "@/components/mobileFooterComponent.vue";
import ChipMobileComponent from "@/components/new/inputs/ChipMobileComponent.vue";
import {useRoute} from "vue-router";
import {useHead} from "@unhead/vue";

export default {
  name: "CartaRestaurante",
  components: {
    ChipMobileComponent,
    MobileFooterComponent,
    SearchComponent,
    DarkRadioFilterChip,
    ProductoCarta,
    ModalProductCustomization,
    LoadingBigComponent
    // WhatsappIconComponent,
  },

  setup() {
    const route = useRoute();
    const canonicalUrl = `${process.env.VUE_APP_BASE_URL}${route.path}`;
    useHead({
      title: `Nuestra carta`,
      link: [
        { rel: 'canonical', href: canonicalUrl }
      ],
      meta: [
        {
          name: "description",
          content:
              `Carta de ${process.env.VUE_APP_BUSINESS_COMERCIAL_NAME}`,
        },
        { name: "author", content: "Quick eat!" },
        { name: "copyright", content: "Quick eat!" },

        { name: "robots", content: "index, follow" },
        { name: "googlebot", content: "index, follow" },
        { name: "language", content: "Spanish, English" },
        { name: "rating", content: "General" },
      ],
    });
  },
  async mounted() {
    window.scrollTo(0,0)
    this.setOrderLocal(null);
    this.local_id = this.$route.params.idLocal;
    this.setIsSelfService(true)
    console.log("llama self", this.isSelfService)
    await this.loadLocalInfo()
    await this.loadCartas();
    await this.loadProdsByParam();
    await this.loadAdditionalItems();
    this.loading = false

  },
  computed: {
    ...mapState(['isAuthenticated', 'orderLocal', 'isSelfService']),
  },

  data () {
    return {
      listCartas: [],
      listNewProducts: [],
      list_additional_items: [],
      modal_prod_id: 0,
      old_id_cat: 0,
      showModalCustomization: false,
      first_load_page: true,
      anim: null,
      local_id: 0,
      whatsapp_phone: '',
      rawListServCategories: [],
      listCategorias: [],
      categorySelected: null,
      listFilteredProducts: [],
      auxCategSelected: null,
      search_input: '',
      loading: true,
    }
  },
  watch: {
    $route: async function () {
      if (this.$route.name === 'CartaRestaurante') {
        this.local_id = this.$route.params.idLocal;
        await this.loadLocalInfo()
        await this.loadCartas();
        await this.loadAdditionalItems();
        await this.loadProdsByParam();
      }
    }
  },
  methods: {
    ...mapMutations(['setOrderLocal', 'setShowShoppingCart', 'setIsSelfService']),
    getWhatsappPhone() {
      this.whatsapp_phone = process.env.VUE_APP_WHATSAPP_PHONE
    },
    openModalCarrito() {
      this.showModalCustomization = false
      this.setShowShoppingCart(true)
    },
    changedSearchInfo() {
      let search = this.search_input.toLowerCase()
      let temp_array = []
      this.listFilteredProducts = this.listNewProducts.filter(item => {
        let name = item.name.toLowerCase()
        if (name.includes(search)) {
          return item
        }
      })
      for (let i=0; i<this.listFilteredProducts.length; i++) {
        temp_array.push(this.listFilteredProducts[i].category)
      }
      this.categorySelected = null
      this.auxCategSelected = null
      this.listCategorias = this.rawListServCategories

    },
    selectedAuxCateg() {
      if (this.auxCategSelected == null) {
        this.categorySelected = null
        this.listCategorias = this.rawListServCategories
        this.search_input = ''
        this.listFilteredProducts = this.listNewProducts
        return
      }
      this.listCategorias = this.rawListServCategories.filter(item => {
        if (item.id === this.auxCategSelected.id) {
          this.categorySelected = item
          return item
        }
      })
      this.listFilteredProducts = this.listNewProducts
      this.search_input = ''
      this.setNewCategory(this.categorySelected)

    },
    selectedRadioBtn() {
      if (this.categorySelected == null) {
        this.auxCategSelected = null
        this.listCategorias = this.rawListServCategories
        this.search_input = ''
        this.listFilteredProducts = this.listNewProducts
        return
      }
      this.listCategorias = this.rawListServCategories.filter(item => {
        if (item.id === this.categorySelected.id) {
          this.auxCategSelected = item
          return item
        }
      })
      this.listFilteredProducts = this.listNewProducts
      this.search_input = ''
      this.setNewCategory(this.categorySelected)
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      this.anim.setSpeed(1)
      this.anim.playSegments([0, 50], true)
      this.anim.play()
    },
    gotoSection() {
      try {
        let a = document.getElementById('subtitulo_carta')
        a.scrollTop = a.scrollHeight
        window.scrollTo(0, a.offsetTop - 64)
      } catch (e) {
        console.log("error", e)
      }
      // window.scrollTo(0, a.offsetTop)

    },
    closeModalCustomization () {
      this.showModalCustomization = false
    },


    backgroundPlaceholderLocal() {
      if (this.orderLocal === null) {
        return {background:`url(${process.env.VUE_APP_IMG_PLACEHOLDER}) center center`,'background-size':'cover', '-webkit-filter': 'grayscale(1)', 'filter': 'grayscale(1)'}
      }
      let url;
      try {
        // eslint-disable-next-line no-unused-vars
        url = new URL(this.orderLocal.background_img)
        if (!this.orderLocal.is_available_local) {
          return {background:`url(${this.orderLocal.background_img}) center center`,'background-size':'cover', '-webkit-filter': 'grayscale(1)', 'filter': 'grayscale(1)'}
        }
        return {background:`url(${this.orderLocal.background_img}) center center`,'background-size':'cover' }
      } catch (error) {
        if (!this.orderLocal.is_available_local) {
          return {background:`url(${process.env.VUE_APP_IMG_PLACEHOLDER}) center center`,'background-size':'cover', '-webkit-filter': 'grayscale(1)', 'filter': 'grayscale(1)'}
        }
        return {background:`url(${process.env.VUE_APP_IMG_PLACEHOLDER}) center center`,'background-size':'cover' }
      }
    },

    backgroundPlaceholderItem(product) {
      let url;
      try {
        // eslint-disable-next-line no-unused-vars
        url = new URL(product.img)
        if (!product.is_available) {
          return {background:`url(${product.img}) center center`,'background-size':'cover', '-webkit-filter': 'grayscale(1)', 'filter': 'grayscale(1)'}
        }
        return {background:`url(${product.img}) center center`,'background-size':'cover' }
      } catch (error) {
        if (!product.is_available) {
          return {background:`url(${process.env.VUE_APP_IMG_PLACEHOLDER}) center center`,'background-size':'cover', '-webkit-filter': 'grayscale(1)', 'filter': 'grayscale(1)'}
        }
        return {background:`url(${process.env.VUE_APP_IMG_PLACEHOLDER}) center center`,'background-size':'cover' }
      }
    },
    async loadLocalInfo() {
      let response = await LocalServices.getLocalInformation(this.local_id)

      if (response.data.success) {
        this.setOrderLocal(response.data.data[0])
      } else {
        this.$toast.error(response.data.message)
      }
    },
    async loadCartas () {
      try {
        let response = await LocalServices.listCategoriesOfLocal(this.orderLocal.id)
        if (response.data.success) {
          let promo = response.data.data.filter(carta => {
            if (!carta.deleted && carta.is_promotion) {
              return carta
            }
          })
          promo.sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          let normal = response.data.data.filter(carta => {
            if (!carta.deleted && !carta.is_promotion) {
              return carta
            }
          })
          normal.sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });

          this.rawListServCategories = promo.concat(normal)
          this.listCategorias = this.rawListServCategories
        } else {
          this.$toast.error(response.data.message)
        }
      }
      catch (error) {
        console.log(error)
      }
    },

    async loadProdsByParam () {
      try {
        let response = await LocalServices.listAllProductsFromLocal('2', this.orderLocal.id)
        if (response.data.success) {
          this.listNewProducts = response.data.data.filter(product => {
            if (!product.deleted) {
              return product
            }
          })
          this.listNewProducts.sort(function (a, b) {
            if (a.position > b.position) {
              return 1;
            }
            if (a.position < b.position) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
          this.listFilteredProducts = [...this.listNewProducts]

        } else {
          console.log("error")
        }
      } catch (error) {
        console.log("error aqui", error)
      }
    },

    async loadAdditionalItems () {
      let response = await LocalServices.listOfAditionalItems(this.orderLocal.id)
      if (response.data.success) {
        this.list_additional_items = response.data.data.filter((additional) => {
          if (!additional.deleted) {
            return additional
          }
        })
        for (let i = 0; i < this.list_additional_items.length; i++) {
          this.list_additional_items[i].cantidad = 0
        }
      } else {
        this.$toast.error(response.data.message)
      }
    },

    async setNewCategory(category) {
      this.categorySelected = category
    },

    setNewProductSelected(product) {
      if (!product.is_available) {
        this.$toast.error(this.$t('cartarestaurante.txt_producto_agotado'))
        return
      }
      this.modal_prod_id = product.id
      this.showModalCustomization = true
    },
  }
}
</script>

<style scoped>
.cartadigital{
  background-color: var(--bg-carta-digital);
}

.cartadigital__hero{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 70px;
}

.box_sombra_sobre_foto {
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  background-size: contain;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

.carta__title{
  position: relative;
  color: var(--text-white);
  text-align: center;
  z-index: 3;
}

.cartadigital__filters h1{
  text-align: center;
  margin-bottom: 16px;
}

.filters{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
}

.filters .search{
  width: fit-content;
  max-width: fit-content;
}

.filter__chips{
  display: none;
}

.cartadigital__dish h2{
  text-align: center;
  margin-bottom: 16px;
}

.dish__list{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

@media screen and (min-width: 600px){
  .cartadigital__hero{
    height: 100px;
  }

  .dish__list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }
}

@media screen and (min-width: 905px){
  .cartadigital__hero{
    height: 150px;
  }

  .filters{
    justify-content: flex-start;
    gap: 20px;
  }

  .select__component__body__wrapper{
    display: none;
  }

  .filter__chips{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
  }
}

@media screen and (min-width: 1240px){
  .cartadigital__hero{
    height: 200px;
  }

  .filter__chips{
    gap: 20px;
  }

  .dish__list{
    gap: 24px;
  }
}

@media screen and (min-width: 1440px) {
  .cartadigital__hero {
    height: 230px;
  }

  .cartadigital__filters {
    padding: 40px 0 0;
  }

  .cartadigital__filters h1{
    margin-bottom: 30px;
  }

  .filters {
    gap: 30px;
  }

  .cartadigital__dish h2 {
    margin-bottom: 30px;
  }
}
</style>