<template>
  <MainHeader :routeName="routeName"/>
  <transition name="slide-fade">
    <ModalLoginRegister
        v-if="showModalLoginRegister"
        @close="closeModalLoginRegister"
    ></ModalLoginRegister>
  </transition>
  <transition name="slide-fade">
    <ModalBusinessBanner
        v-if="showModalBusinessBanner"
        :file="businessInfo.img_banner"
        @closeImgBanner="showModalBusinessBanner=false"
    >
    </ModalBusinessBanner>
  </transition>
  <transition name="slide-fade">
    <ModalSMSValidation
        v-if="showModalUserSmsValidation"
        :businessInfo="businessInfo"
        @closeSmsModal="showModalUserSmsValidation=false"
        @smsValidated="validation_successful"
    >
    </ModalSMSValidation>
  </transition>
  <main>
    <router-view></router-view>
  </main>
  <transition name="slide-fade">
    <ModalDelivery
        v-if="showModalDelivery"
        @close="showModalDelivery=false"
    ></ModalDelivery>
  </transition>
  <transition name="slide-fade">
    <ModalPreDelivery
        v-if="showModalPreDelivery"
        @close="showModalPreDelivery=false"
        @addAddress="showModalPreDelivery=false;showModalDelivery=true">
    </ModalPreDelivery>
  </transition>
  <transition name="slide-fade">
    <ModalListLocals
        v-if="showModalListLocals"
        @closeModalLocal="showModalListLocals=false">
    </ModalListLocals>
  </transition>
  <transition name="slide-fade">
    <ModalOfflineUser
        v-if="showModalOfflineUser"
        @emitCloseModal="showModalOfflineUser = false"
    />
  </transition>
  <FooterComponent></FooterComponent>
</template>

<script>

import {mapMutations, mapState} from "vuex"
import router from './router'
import OrderServices from "../src/services/order-services"
import Utils from "../src/services/util-services"
import User from "../src/services/user-services"
import { defineAsyncComponent } from 'vue';
import { db } from "./firebase"
import {doc, onSnapshot} from "firebase/firestore";
import * as Sentry from "@sentry/vue";
import LocalServices from "@/services/local-services";
import ModalListLocals from "@/components/MODALS/ModalListLocals";
import ModalOfflineUser from "@/components/MODALS/ModalOfflineUser";


export default {
  name: 'App',
  components: {
    ModalOfflineUser,
    ModalListLocals,
    ModalDelivery: defineAsyncComponent(() => import( "@/components/MODALS/ModalDelivery.vue")),
    ModalPreDelivery: defineAsyncComponent(()=>import("./components/MODALS/ModalPreDelivery.vue")),
    MainHeader: defineAsyncComponent(() => import("./components/MainHeader")),
    ModalLoginRegister: defineAsyncComponent(() => import("@/components/MODALS/ModalLoginRegister")),
    ModalBusinessBanner: defineAsyncComponent(() => import("@/components/MODALS/ModalBusinessBanner")),
    ModalSMSValidation: defineAsyncComponent(() => import("@/components/MODALS/ModalSMSValidation")),
    FooterComponent: defineAsyncComponent(() => import('@/components/FooterComponent'))
  },
  data () {
    return {
      firestore_object: null,
      initialListen: true,
      localId: '',
      order_id: '',
      showModalBusinessBanner: false,
      showModalUserSmsValidation: false,
      showModalPreDelivery:false,
      showModalDelivery: false,
      showModalListLocals: false,
      showModalOfflineUser: false,
      routeName: '',
      is_ssr: false,
    }
  },
  watch: {
    goToPayClicked: function () {
      if (this.goToPayClicked) {
        if (this.isSelfService) {
          this.$router.push('/micarrito/pagar')
          this.setGoToPayClicked(false)
          this.setShowShoppingCart(false)
          return
        }
        if (this.typeOrder === 3) {
          this.showModalPreDelivery = true
        } else {
          this.showModalListLocals = true
        }
        this.setGoToPayClicked(false)
      }
    },
    isAuthenticated: async function () {

      if (this.isAuthenticated) {
        await User.setAuthHeaders(this.user.token)
        setTimeout(async () => {

          if (this.$route.name !== 'pedidos') {
            await this.loadRecentOrder()
          }
        }, 2000)
        await this.getShoppingCart()

        if (this.user.is_verify) {
          return
        }
        if (this.businessInfo === null) {
          return
        }
        if (this.businessInfo.business_permissions.some(permission => permission === '2' || permission === '8')) {
          this.showModalUserSmsValidation = true;
        }
        return
      }

      if (this.firestore_object !== null) {
        this.initialListen = true
        this.firestore_object()
      }
      await User.setAuthHeaders(null)
      await this.getShoppingCart()
    },
    $route: async function () {
      this.routeName = this.$route.name
      if (this.$route.name === 'pedidos') {
        if (this.firestore_object !== null) {
          this.firestore_object()
        }
      }
    }
  },
  async mounted() {
    this.routeName = this.$route.name
    await this.getLocals()
    await this.getShoppingCart()

  },
  async created() {
    this.is_ssr = typeof window === 'undefined';
    if (this.isAuthenticated) {
      await User.setAuthHeaders(this.user.token)

      if (process.env.NODE_ENV !== 'development' && !this.is_ssr) {
        if (this.isAuthenticated) {
          Sentry.setUser({
            email: this.user.email,
            phone: this.user.phone,
            full_name: this.user.first_name + " " + this.user.last_name,
            business: process.env.VUE_APP_BUSINESS
          });
        } else {
          Sentry.configureScope(scope => scope.setUser(null));
        }
      }
    } else {
      await User.setAuthHeaders(null)
    }

    if ((this.webVersion < parseInt(process.env.VUE_APP_CURRENT_VERSION)) && this.webVersion !== 0) {
      this.setWebVersion(parseInt(process.env.VUE_APP_CURRENT_VERSION))
      window.location.reload()
    }

    let response = await Utils.getBusinessInfo()
    if (response.data.success) {
      this.setBusinessInfo(response.data.data)
    } else {
      this.$toast.error(response.data.message)
      return
    }
    if (this.businessInfo.is_available_banner) {
      this.showModalBusinessBanner = true
    }

  },
  computed: {
    ...mapState(['showModalLoginRegister', 'isAuthenticated', 'user', 'goToPayClicked', 'typeOrder', 'isSelfService',
      'businessInfo']),
  },
  methods: {
    ...mapMutations(['setGoToPayClicked', 'setShowModalLoginRegister', 'setListLocals', 'setShoppingCartInfo',
      'setAuthenticatedFalse', 'setWebVersion', 'setBusinessInfo', 'clearProducts', 'setShowShoppingCart']),

    async getShoppingCart() {
      if (this.isAuthenticated) {
        try {

          let response = await OrderServices.getCurrentShoppingCart()
          if (response.data.success) {
            this.setShoppingCartInfo(response.data.data)
          } else {
            this.$toast.error(response.data.message)
          }
        } catch (e) {
          if (e.toString().includes('401')) {
            this.$toast.error('Sesión expirada. Por favor, vuelva a iniciar sesión.')
            this.setAuthenticatedFalse()
            this.clearProducts()
            await User.setAuthHeaders(null)
            if (!this.is_ssr) {
              localStorage.clear()
            }
            this.setWebVersion(parseInt(process.env.VUE_APP_CURRENT_VERSION))
            await this.$router.push("/")
            setTimeout(() => {
              window.location.reload()
            }, 1500)
          }
          console.log("error", e)
        }
      } else {
        this.setShoppingCartInfo([])
      }
    },

    async getLocals() {
      let response = await LocalServices.listLocalesCercanos()
      if (response.data.success) {
        response.data.data = response.data.data.filter(item => {
          if (!item.deleted) {
            return item
          }
        })
        for (let i = 0; i < response.data.data.length; i++) {
          const dayLists = [[], [], [], [], [], [], []];

          for (let g = 0; g < response.data.data[i].schedules.length; g++) {
            const day = parseInt(response.data.data[i].schedules[g].day, 10);
            dayLists[day].push(response.data.data[i].schedules[g]);
          }
          for (let z=0; z < dayLists.length; z++) {
            if (dayLists[z].length > 0) {
              dayLists[z] = dayLists[z].sort(function (a, b) {
                if (a.time_init > b.time_init) {
                  return 1;
                }
                if (a.time_init < b.time_init) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });
            }
          }
          response.data.data[i].day_list = dayLists
        }
        this.setListLocals(response.data.data)
        return
      }
    },
    closeModalLoginRegister() {
      this.setShowModalLoginRegister(false)
    },
    validation_successful() {
      this.$toast.success('Número celular validado con éxito. ¡Gracias!. ')
      this.showModalUserSmsValidation = false
    },
    async loadRecentOrder () {
      let response = await OrderServices.recentOrder()
      if (response.data.success) {
        if (response.data.data.type_delivery != null) {
          this.order_id = response.data.data.id
          this.localId = response.data.data.local_id.toString()
          if (response.data.data.status !== '3' && response.data.data.status !== '4') {
            this.fireStoreListenner()
          }
        }
      }
    },
    fireStoreListenner() {

      this.firestore_object = onSnapshot(doc(db, `quickeat/${process.env.VUE_APP_BUSINESS}/${this.localId}/${this.order_id}`),
          {includeMetadataChanges: true},
          (doc) => {
            if (this.initialListen) {
              this.initialListen = false
              console.log("hizo initial listen")
            } else {
              if (doc.data() !== undefined) {

                let response = doc.data().status
                switch (response) {
                  case '0':
                    response = 'Tu pedido está pendiente'
                    break
                  case '1':
                    response = 'Tu pedido se está cocinando'
                    break

                  case '2':
                    response = 'Tu pedido está listo para recojo'
                    break
                  case '3':
                    response = 'Tu pedido fue anulado'
                    break
                  case '4':
                    response = 'Tu pedido fue entregado con éxito. ¡Buen provecho!'
                    break
                  case '5':
                    response = 'Mesa reservada con éxito'
                    break
                  case '6':
                    response = 'Tu pedido está en camino'
                    break
                }

                const notification = {
                  title: 'Quick eat!',
                  options: {
                    body: response,
                    requireInteraction: true,
                    icon: 'https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png',
                    image: 'https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png',
                    badge: 'https://quickeat.s3-us-west-2.amazonaws.com/Digital+media/logo_app_quick_eat.png'
                  },
                  events: {
                    onerror: function () {
                      //console.log('Error en la notif');
                    },
                    onclick: function () {
                      router.push('/perfil/pedidos');
                    },
                    onclose: function () {
                    },
                    onshow: function () {
                    }
                  }
                }

                this.$notification.show(notification.title, notification.options, notification.events)

                this.$toast.success(response)
              }
            }
          })
    }
  }
}
</script>
<style scoped>
main{
  flex-grow: 1;
}
</style>