<template>
  <div class="rastreo">
    <CarouselRastreo
        :info_carousel="info_carousel_rastreo"
    >
    </CarouselRastreo>
    <div class="section-main infocomponent">
      <OrderInfoComponent
          :from_tracking=true
          :allow_back=false
      >
      </OrderInfoComponent>
    </div>
  </div>
</template>

<script>
import OrderInfoComponent from "@/components/OrderInfoComponent";
import CarouselRastreo from "@/components/CarouselRastreo.vue";
import {useRoute} from "vue-router";
import {useHead} from "@unhead/vue";

export default {
  name: "RastreoPedido.vue",

  components: {
    OrderInfoComponent,
    CarouselRastreo
},

  setup() {
    const route = useRoute();
    const canonicalUrl = `${process.env.VUE_APP_BASE_URL}${route.path}`;
    useHead({
      title: `Seguimiento de pedido`,
      link: [
        { rel: 'canonical', href: canonicalUrl }
      ],
      meta: [
        {
          name: "description",
          content:
              `Seguimiento de pedido en ${process.env.VUE_APP_BUSINESS_COMERCIAL_NAME}`,
        },
        { name: "author", content: "Quick eat!" },
        { name: "copyright", content: "Quick eat!" },

        { name: "robots", content: "index, follow" },
        { name: "googlebot", content: "index, follow" },
        { name: "language", content: "Spanish, English" },
        { name: "rating", content: "General" },
      ],
    });
  },

  data () {
    return {
      info_carousel_rastreo: [
        {
          imgMobile: require('../assets/images/rebrandable/banner-rastreo-1-mobile.webp'),
          title: '¡Atención express!',
          paragraph: 'Los pedidos en línea son procesados con la mayor rapidez posible. ¡Ayúdanos a servirte mejor!',
          imgDesktop: require('../assets/images/rebrandable/banner-rastreo-1-desktop.webp'),
        },
        {
          imgMobile: require('../assets/images/rebrandable/banner-rastreo-2-mobile.webp'),
          title: 'Compra al instante',
          paragraph: 'Pide tus productos favoritos de una manera fácil y rápida.',
          imgDesktop: require('../assets/images/rebrandable/banner-rastreo-2-desktop.webp'),
        },
        {
          imgMobile: require('../assets/images/rebrandable/banner-rastreo-3-mobile.webp'),
          title: '¡Acumula tus puntos!',
          paragraph: 'Manu café, siempre premiará tu preferencia ¡Acumula puntos y canjéalos!',
          imgDesktop: require('../assets/images/rebrandable/banner-rastreo-3-desktop.webp'),
        },
      ],

    }
  },
  async  mounted () {

  },
  methods: {
  },
  computed: {
  },
  watch: {
  },
}
</script>
<style scoped>
  @media only screen and (min-width: 905px){
    .rastreo{
      display: grid;
      grid-template-columns: 1fr 1.5fr;
      gap: 30px;
      padding: 25px 24px;
      margin-inline: auto;
      max-width: 1224px;
    }

    .infocomponent{
      padding: 0;
    }
  }

  @media only screen and (min-width: 1240px){
    .rastreo{
      grid-template-columns: 1fr 2fr;
    }
  }
</style>
