import {createSSRApp, createApp} from 'vue';
import App from './App.vue';

import router from './router'
import store from './store'
const AOS = require('aos')
import "@/assets/styles/qe_datepicker.css"
import "@/assets/styles/style.css"
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import VueGtag from "vue-gtag"
const Sentry = require("@sentry/vue");
import i18n from './i18n'
import { VueHeadMixin, createHead } from "@unhead/vue";


const isSSR = typeof window === 'undefined';

export default function () {

    const app = (isSSR ? createSSRApp(App) : createApp(App));
    const head = createHead();
    app.use(i18n)
    if (!isSSR) {
        app.mixin({
            created() {
                AOS.init({
                    duration: 1000
                })
            }
        })
    } else {
        app.mixin({
            mounted() {
                AOS.init({
                    duration: 1000
                });
            }
        });
    }

    app.use(Vue3Lottie)

// Plugins
    app.use(ToastPlugin, {
        position: 'top-right',
        duration: 5000
    });
    app.use(VueGtag, {
        config: { id: process.env.VUE_APP_GTAG_ID }
    })


// Router and Store
    app.use(router)
    app.use(store)
    if (process.env.NODE_ENV !== 'development' && !isSSR)  {
        console.log("importa sentry mainjs")
        Sentry.init({
            app,
            dsn: "https://1da32c178db647f4b40035057482838c@o1061511.ingest.sentry.io/6097575",
        });
    }

    app.mixin(VueHeadMixin);
    app.use(head)

    return { app, router, store, head };
}